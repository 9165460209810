import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input, Modal, Tab, TabPane } from 'semantic-ui-react';
import { Button } from '../../../../components/button/Button';
import { Checkbox } from '../../../../components/checkbox/Checkbox';
import { Icon } from '../../../../components/icon/Icon';
import { axios } from '../../../../services/axiosService';
import {
    loadPaymentInformation,
    loadPaymentProfile,
} from '../billingSettingsActionCreators';
import {
    setAutopayAccount,
    setAutopayCustomerId,
    setAutopayLocationId,
    setAutopayModalOpen,
    useAppStateBillingSettingsSelector,
} from '../billingSettingsSlice';
import styles from './AutopayConfigModal.module.scss';
import { openMessageModal } from '../../../../components/statusModal/messageModalActionCreators';
import { AnyAction } from 'redux';
import { useFeatureFlag } from '../../../featureFlags/FeatureFlag';
import { useAuthSelector } from '../../../auth/authSlice';

interface AutopayConfigModalProps {
    modalOpen: boolean;
    enabled?: boolean;
    savedCard?: string;
    id?: string;
}

export const AutopayConfigModal = ({
    modalOpen,
    enabled: propEnabled,
    savedCard: propSavedCard,
    id: propId,
}: AutopayConfigModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [enabled, setEnabled] = useState(propEnabled ?? false);
    const [apiError, setApiError] = useState('');
    const [savedCard, setSavedCard] = useState(propSavedCard ?? undefined);
    const [id, setId] = useState<string | undefined>();

    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [mismatchAccountNumber, setMismatchAccountNumber] = useState(false);
    const [mismatchRoutingNumber, setMismatchRoutingNumber] = useState(false);

    const coreFF = useFeatureFlag('EnableCORE');
    const enableAchAutopay = useFeatureFlag('EnableACHAutopay');

    const { authUser } = useAuthSelector((state) => state);
    const {
        paymentProfile,
        autopayLocationId,
        autopayCustomerId,
        coreStoredPayments,
        autopayStatus,
    } = useAppStateBillingSettingsSelector((s) => s);

    const [tab, setTab] = useState(
        autopayStatus ? (autopayStatus === 'C' ? 1 : 0) : 0,
    );

    useEffect(() => {
        setEnabled(propEnabled ?? false);
        setSavedCard(propSavedCard ?? undefined);
        setId(propId);
    }, [propEnabled, propSavedCard, propId]);

    const onClose = () => {
        setApiError('');
        dispatch(setAutopayAccount(null));
        dispatch(setAutopayModalOpen(false));
    };

    const onSubmit = async () => {
        try {
            if (enabled) {
                if (tab === 0) {
                    // Check if Cards are selected
                    if (
                        !savedCard &&
                        ((!coreFF?.enabled && !paymentProfile.length) ||
                            (coreFF?.enabled && !coreStoredPayments.length))
                    ) {
                        setApiError(t('Billing.ConfigureAutopayNoCardsError'));
                        return;
                    } else if (
                        !savedCard &&
                        ((!coreFF?.enabled && paymentProfile.length) ||
                            (coreFF?.enabled && coreStoredPayments.length))
                    ) {
                        setApiError(
                            t('Billing.ConfigureAutopaySelectCardError'),
                        );
                        return;
                    }
                } else {
                    // Check if Bank Account is selected
                    if (!accountNumber || !routingNumber || !accountType) {
                        setApiError('Please fill out all fields');
                        return;
                    }

                    var error = false;

                    if (accountNumber !== confirmAccountNumber) {
                        setMismatchAccountNumber(true);
                        error = true;
                    } else {
                        setMismatchAccountNumber(false);
                    }

                    if (routingNumber !== confirmRoutingNumber) {
                        setMismatchRoutingNumber(true);
                        error = true;
                    } else {
                        setMismatchRoutingNumber(false);
                    }

                    if (error) {
                        return;
                    }
                }

                const data = {
                    navilineCustomerId: autopayCustomerId,
                    navilineLocationId: autopayLocationId,
                } as any;

                if (tab === 0) {
                    data.savedCard = savedCard?.toString();
                } else {
                    data.accountNumber = accountNumber;
                    data.routingNumber = routingNumber;
                    data.accountType = accountType;
                }

                await axios.put('/autopay', data);
                dispatch(
                    openMessageModal(
                        'success-update',
                        'success',
                    ) as unknown as AnyAction,
                );
            } else {
                if (id === null) {
                    await axios.delete(`/autopay/`, {
                        data: {
                            accountNumber:
                                autopayCustomerId + '-' + autopayLocationId,
                        },
                    });
                } else {
                    await axios.delete(`/autopay/${id}`);
                }

                dispatch(
                    openMessageModal(
                        'success-update',
                        'success',
                    ) as unknown as AnyAction,
                );
            }

            dispatch(loadPaymentInformation());
            dispatch(loadPaymentProfile(authUser?.Id, coreFF?.enabled));
            dispatch(setAutopayModalOpen(false));
            dispatch(setAutopayAccount(null));
            dispatch(setAutopayCustomerId(null));
            dispatch(setAutopayLocationId(null));
        } catch (err) {
            console.error(err);
            dispatch(
                openMessageModal(
                    'error-update',
                    'error',
                ) as unknown as AnyAction,
            );
            setApiError(t('Billing.AutopaySaveError'));
        }
        return;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const bankAccount = () => {
        return (
            <>
                <div style={{ paddingTop: '20px' }} />

                <p
                    style={{
                        paddingTop: '15px',
                        color: '#005daa',
                        width: '500px',
                    }}
                >
                    The City of Elgin Portal does not store your bank account
                    information. Instead, your details are securely encrypted
                    and transmitted directly to our financial system, which
                    operates within a secured network environment, ensuring the
                    highest level of protection for your information.
                </p>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <p>Routing Number</p>
                        <Input
                            value={routingNumber}
                            onChange={(e, data) => {
                                setRoutingNumber(data.value);
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ width: '20px' }} />
                    <div>
                        <p>Confirm Routing Number</p>
                        <Input
                            value={confirmRoutingNumber}
                            onChange={(e, data) => {
                                setConfirmRoutingNumber(data.value);
                            }}
                        />
                    </div>
                </div>
                {mismatchRoutingNumber && (
                    <div style={{ color: 'red', paddingTop: '10px' }}>
                        Routing numbers do not match
                    </div>
                )}

                <div style={{ paddingTop: '40px' }} />

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                        <p>Account Number</p>
                        <Input
                            value={accountNumber}
                            onChange={(e, data) => {
                                setAccountNumber(data.value);
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ width: '20px' }} />
                    <div>
                        <p>Confirm Account Number</p>
                        <Input
                            value={confirmAccountNumber}
                            onChange={(e, data) => {
                                setConfirmAccountNumber(data.value);
                            }}
                        />
                    </div>
                </div>

                {mismatchAccountNumber && (
                    <div style={{ color: 'red', paddingTop: '10px' }}>
                        Account numbers do not match
                    </div>
                )}

                <div style={{ paddingTop: '20px' }} />
                <div className="row">
                    <Checkbox
                        onChange={(e, d) => {
                            const checked = d.checked;

                            if (checked) {
                                setAccountType('checking');
                            }
                        }}
                        text="Checking Account"
                        value={accountType === 'checking'}
                    />
                    <div style={{ paddingTop: '10px' }} />
                    <Checkbox
                        onChange={(e, d) => {
                            const checked = d.checked;

                            if (checked) {
                                setAccountType('savings');
                            }
                        }}
                        text="Savings Account"
                        value={accountType === 'savings'}
                    />
                </div>
            </>
        );
    };

    return (
        <Modal open={modalOpen} onClose={onClose} size="small">
            <Modal.Header>
                {t('Billing.ConfigureAutopay')}
                <div style={{ float: 'right' }}>
                    <Icon
                        icon="x"
                        color="black"
                        size="large"
                        onClick={onClose}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    {apiError !== '' && (
                        <p className={styles.errorMessage}>{apiError}</p>
                    )}
                    <div style={{ paddingBottom: '20px' }}>
                        <Checkbox
                            value={enabled}
                            onChange={(_) => setEnabled(!enabled)}
                            text={t('Billing.EnableAutopay')}
                        />
                    </div>

                    {enabled && (
                        <Tab
                            defaultActiveIndex={tab}
                            onTabChange={(e, data) => {
                                if (data.activeIndex) {
                                    setTab(data.activeIndex as number);
                                }
                            }}
                            panes={[
                                {
                                    menuItem: 'Cards',
                                    render: () => (
                                        <TabPane>
                                            {(!coreFF?.enabled &&
                                                paymentProfile.length) ||
                                            (coreFF?.enabled &&
                                                coreStoredPayments &&
                                                coreStoredPayments.length) ? (
                                                <div
                                                    className={
                                                        styles.formContainer
                                                    }
                                                >
                                                    <div>
                                                        {t(
                                                            'Billing.SelectCard',
                                                        )}
                                                        :
                                                    </div>
                                                    <div>
                                                        {coreFF?.enabled
                                                            ? coreStoredPayments.map(
                                                                  (x: any) => (
                                                                      <div
                                                                          className={
                                                                              styles.radioButtonContainer
                                                                          }
                                                                      >
                                                                          <Checkbox
                                                                              key={
                                                                                  x.id
                                                                              }
                                                                              text={`${x.card_type} - ${x.accountnum}`}
                                                                              value={
                                                                                  savedCard &&
                                                                                  x &&
                                                                                  Number(
                                                                                      savedCard,
                                                                                  ) ===
                                                                                      Number(
                                                                                          x.id,
                                                                                      )
                                                                              }
                                                                              onChange={() => {
                                                                                  setSavedCard(
                                                                                      x.id,
                                                                                  );
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  ),
                                                              )
                                                            : paymentProfile.map(
                                                                  (x: any) => (
                                                                      <Checkbox
                                                                          key={
                                                                              x.Id
                                                                          }
                                                                          text={`${x.Type} - ${x.MaskedAccountNumber}`}
                                                                          value={
                                                                              savedCard ===
                                                                              x.Id
                                                                          }
                                                                          onChange={() =>
                                                                              setSavedCard(
                                                                                  x.Id,
                                                                              )
                                                                          }
                                                                      />
                                                                  ),
                                                              )}
                                                    </div>
                                                </div>
                                            ) : enabled &&
                                              ((!coreFF?.enabled &&
                                                  !paymentProfile.length) ||
                                                  (coreFF?.enabled &&
                                                      coreStoredPayments &&
                                                      !coreStoredPayments.length)) ? (
                                                <>
                                                    <div
                                                        className={
                                                            styles.errorMessage
                                                        }
                                                    >
                                                        {t(
                                                            'Billing.NoSavedCards',
                                                        )}
                                                    </div>
                                                </>
                                            ) : null}
                                        </TabPane>
                                    ),
                                },
                                ...(enableAchAutopay?.enabled
                                    ? [
                                          {
                                              menuItem: 'Bank Account',
                                              render: bankAccount,
                                          },
                                      ]
                                    : []),
                            ]}
                        />
                    )}

                    <div className={styles.buttonWrapper}>
                        {autopayStatus && autopayStatus === 'C' && (
                            <div
                                style={{
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                }}
                            >
                                <p style={{ color: 'red', width: '500px' }}>
                                    AutoPay is already configured with ACH,
                                    setting this again will remove the existing
                                    ACH account.
                                </p>
                            </div>
                        )}
                        <Button onClick={() => onSubmit()}>Save</Button>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
};
